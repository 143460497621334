import React from "react";

import PropTypes from "prop-types";

// import Chip from "./components/chip";
// import Footer from "./components/footer";
// import Header from "./components/header";
import { Body, LayoutWrapper } from "./layout.styled";

interface LayoutProps {
  children: React.ReactNode;
  location?: Location | undefined;
}

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  location,
}: LayoutProps) => {
  return (
    <LayoutWrapper id="main-container">
      {/* <Header /> */}
      <Body>
        {children}
        {/* <Chip /> */}
      </Body>
      {/* <Footer /> */}
    </LayoutWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.any,
};

export default Layout;
